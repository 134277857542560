(function() {
    const countdowns = document.querySelectorAll('.js-countdown');

    if (countdowns) {
		countdowns.forEach((countdown) => {
			const countdownTime = countdown.getAttribute('data-date');

      const date = new Date(countdownTime);
      console.log(date);

      const finaleDate = new Date(countdownTime).getTime();
      console.log(finaleDate);
      const timer = () =>{
          const now = new Date().getTime();
          let diff = finaleDate - now;
          // Showing the alert when the counter time finishes.
          if(diff < 0){
            //   console.log('nuls');
			  const sibling = countdown.previousElementSibling;

			  if (sibling.classList.contains('js-countdown-alert')) {
				sibling.style.display = 'block';
				countdown.style.display = 'none';
			  }
          }

          let months = Math.floor(diff / (1000*60*60*24*30))
          let days = Math.floor(diff % (1000*60*60*24*30) / (1000*60*60*24));
          let hours = Math.floor(diff % (1000*60*60*24) / (1000*60*60));
          let minutes = Math.floor(diff % (1000*60*60)/ (1000*60));
          let seconds = Math.floor(diff % (1000*60) / 1000);

		  const monthsElement = countdown.querySelector('.js-countdown-months');
		  if (monthsElement) {
			monthsElement.textContent = String(months).padStart(2, '0');
		  } else {
			days += months * 30;
		  }

          const daysElement = countdown.querySelector('.js-countdown-days');
		  if (daysElement) {
			daysElement.textContent = String(days).padStart(2, '0');
		  } else {
			hours += days * 24;
		  }

          const hoursElement = countdown.querySelector('.js-countdown-hours');
		  if (hoursElement) {
			hoursElement.textContent = String(hours).padStart(2, '0');
		  }

          const minutesElement = countdown.querySelector('.js-countdown-minutes');
		  if (minutesElement) {
			minutesElement.textContent = String(minutes).padStart(2, '0');
		  }

          const secondsElement = countdown.querySelector('.js-countdown-seconds');
		  if (secondsElement) {
			secondsElement.textContent = String(seconds).padStart(2, '0');
		  }


      }
      timer();
      // Calling the function every 1000 milliseconds.
      setInterval(timer,1000);
		});

    }
}());
