import $ from 'jquery';

$('.accordion-container').on('click', '.accordion-head-trigger', function (){
    if(!$(this).hasClass('active')){
        $(this).closest('div').find('table head').each(function (){
            $(this).removeClass('active');
            $(this).siblings('tbody').addClass('d-none');
        });

        $(this).addClass('active');
        $(this).siblings('tbody').removeClass('d-none');
    } else {
        $(this).removeClass('active');
        $(this).siblings('tbody').addClass('d-none');
    }
}).on('click', '.accordion-trigger', function (){
    let tr = $(this).closest('tr');
    if(!tr.hasClass('active')){
        $(this).closest('div').find('table tbody tr').each(function (){
            $(this).removeClass('active');
            $(this).children(':not(:first-child)').removeClass('d-flex').addClass('d-none');
        });

        tr.addClass('active');
        tr.children(':not(:first-child)').removeClass('d-none').addClass('d-flex');
    } else {
        tr.removeClass('active');
        tr.children(':not(:first-child)').removeClass('d-flex').addClass('d-none');
    }
})