// Menu mobile
const menuTrigger = document.querySelector(".js-menu-trigger");
const closeTrigger = document.querySelector(".js-close");
const headerEl = document.querySelector(".header");

if (menuTrigger) {
	menuTrigger.addEventListener("click", () => {
	headerEl.classList.add("menu-open");
	menuTrigger.classList.add("menu-open");
	});

	closeTrigger.addEventListener("click", () => {
	headerEl.classList.remove("menu-open");
	menuTrigger.classList.remove("menu-open");
	});
}
