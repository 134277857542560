import $ from 'jquery';

$(document).ready(function () {
	$(".resend-order-button").click(function () {
		const id = $(this).data('order-id');
		$(this).text($(this).data('text-resending'));
		const button = this;
		$.get("/orders/" + id + "/resend_register_mail", function (result) {
			console.log(result);
			if (result.success) {
				$(button).text($(button).data('text-resend'));
			} else {
				$(button).text($(button).data('text-resend-failed'));
			}
		});
	});
})
