require('./bootstrap');

import './components/menu';
import './components/select';
import './components/resend-claim';
import './components/resend-order';
import './components/countdown';
import './components/modal';
import './components/clipboard';
import './components/accordion';
import './components/table';
