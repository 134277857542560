import $ from 'jquery';
import select2 from 'select2';

$(document).ready(function() {
    $('#language').change(function(e) {
        window.location.href = '/' + e.target.value + window.location.pathname.substring(3)
    });

    $('.form__select').each(function() {
        $(this).select2({
            minimumResultsForSearch: Infinity
        });
    });

    $('select.select2').select2();

    let select2Container = $('.custom-select2-container')
    select2Container.select2({
        placeholder: select2Container.data('placeholder'),
        minimumInputLength: 3,
        dataType: 'json',
        ajax: {
            url: select2Container.data('url'),
            data: function (params) {
                return {
                    search: params.term,
                    type: 'public'
                };
            },
            processResults: function (data) {
                return {
                    results: $.map(data, function(obj) {
                        return {
                            id: obj.id,
                            text: obj.text
                        };
                    })
                };
            }
        }
    });
});
