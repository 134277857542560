import $ from 'jquery';

$.modal = $('#modal');

$('body').on('click', '.modal-pop-up', function (){
    let data = $(this).data();
    delete data.url;

    $.ajax({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        url: $(this).data('url'),
        data: data,
        method: 'get'
    }).done(function(response){
        $.modal.find('.modal-content').html(response);
        $.modal.modal('show');
    })
})

