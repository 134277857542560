import ClipboardJS from 'clipboard';

if(document.querySelector('.js-copy-code')) {
  if(ClipboardJS.isSupported()) {
    const copyBtn = document.querySelector('.js-copy-code');
    const copyToCliboard = new ClipboardJS('.js-copy-code');

    copyBtn.addEventListener('mouseleave', clearTooltip);
    copyBtn.addEventListener('blur', clearTooltip);

    function clearTooltip(e) {
        e.currentTarget.setAttribute('class', 'js-copy-code');
        e.currentTarget.removeAttribute('aria-label');
    }

    function showTooltip(elem, msg) {
        elem.setAttribute('class', 'js-copy-code tooltipped tooltipped-s');
        elem.setAttribute('aria-label', msg);
    }

    copyToCliboard.on('success', function(e) {
	  e.clearSelection();
	  console.log(e);

      showTooltip(e.trigger, 'Copied!');
    });

    copyToCliboard.on('error', function(e) {
      showTooltip(e.trigger, 'Something went wrong...');
    });
  } else {
   document.querySelector('.copy-code-group').classList.add('is-hidden');
  }

  const copyCodes = document.querySelectorAll('.copy-code');
  copyCodes.forEach((copyCode) => {
	copyCode.addEventListener('click', function() {
		this.focus();
		this.select();
	});
  });
}


